import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

declare let videojs: any;

@Component({
  selector: 'app-video-ad-vast',
  templateUrl: './video-ad-vast.component.html',
  styleUrls: ['./video-ad-vast.component.scss'],
})
export class VideoAdVastComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() skip = new EventEmitter<boolean>();
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  showButtonSkip = false;
  isMobile = false;
  uniqueId: string;
  indexInterval: any;

  constructor() {
    this.isMobile = window.outerWidth < 640;
  }

  ngOnInit() {
    this.uniqueId = 'vid' + new Date().getTime(); // Genera un ID único para el video
  }

  ngAfterViewInit() {
    this.indexInterval = setInterval(() => {
      if (this.videoElement) {
        this.initialiceVidAd();
        clearInterval(this.indexInterval);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    clearInterval(this.indexInterval);
  }

  initialiceVidAd() {
    try {
      const videoElement = this.videoElement.nativeElement;
      const vid = videojs(videoElement);

      // Resetea configuraciones anteriores si existen
      if (vid.ads) {
        delete vid.ads;
        delete vid.vast;
      }

      vid.ads();
      vid.muted(true);
      vid.play();
      vid.vast({
        url: 'https://www.videosprofitnetwork.com/watch.xml?key=78c30c49e4811aa99d53ffecd3d42c5a',
      });

      setTimeout(() => {
        this.showButtonSkip = true;
      }, 15000);
    } catch (error) {
      console.log(error);
    }
  }

  clickSkipVideo() {
    const videoElement = this.videoElement.nativeElement;
    videoElement.remove();
    this.skip.emit();
    this.showButtonSkip = false;
  }
}
