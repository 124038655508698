import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, map,catchError } from 'rxjs';
import { SocketService } from '../socket/socket.service';
import { LayoutService } from '../layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private env = environment;
  private urlBlackList = this.env.API_SERVICE_LL_BLACKLIST;
  private urlReport = this.env.API_SERVICE_LL_REPORT;
  private urlSubscription = this.env.API_SERVICE_LL_GET_SUBSCRIPTION;
  private urlModelExternalPayment = this.env.API_SERVICE_LL_MODEL_EXTERNAL_PAYMENT;
  private urlUser = this.env.API_SERVICE_LL_USER;
  private urlMetrics = this.env.API_SERVICE_LL_METRICS;
  private urlState = this.env.API_SERVICE_LL_STATE;



  private followers = new BehaviorSubject<any>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  $followers = this.followers.asObservable();

  private subscribers = new BehaviorSubject<any>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  $subscribers = this.subscribers.asObservable();

  private chatSubscribers = new BehaviorSubject<any>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  $chatSubscribers = this.chatSubscribers.asObservable();


  private userActives = new BehaviorSubject<any>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  $userActives = this.userActives.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthServiceService,
    private socketService: SocketService,
    private layoutService: LayoutService
  ) { }

  // no te subscribas aqui
  getUsersActiveServer() {
    return this.httpClient.get(this.urlUser + '/user/actives');
      // this.userActives.next(res);
    // this.socketService.getUserActives().subscribe((res) => {
    //   this.userActives.next(res);
    // });
  }
  refreshOnlineUser(userId:  any) {
    return this.httpClient.put(this.urlUser + '/user/refresh-online', {userId});
  }

  // subscribete aqui para tener a los usuarios activos
  getUsersActive() {
    return this.$userActives;
  }

  getMyId() {
    return this.authService.getLocalStorage('userId');
  }

  getMyTypeUser() {
    return this.authService.getLocalStorage('userType');
  }

  getAllUserActive() {
    return this.httpClient.get(this.urlUser+ '/user/all-users-actives');
  }

  getMyProfile() {
    return this.httpClient.get(this.urlUser + '/user/my-profile');
  }

  getUsers() {
    return this.httpClient.get(this.urlUser + '/user/users');
  }

  getUser(id: string) {
    return this.httpClient.get(this.urlUser + '/user/user/' + id);
  }

  getUsersGenerators(page, preferences) {
    const queryPreferences = preferences ? '&preferences=' + JSON.stringify(preferences) : '';
    return this.httpClient.get(this.urlUser + '/user/generators?page='+page+queryPreferences);
  }

  getDataProfile(idProfile: string) {
    return this.httpClient.get(
      this.urlUser + '/user/profile/' + idProfile
    );
  }

  registerViewProfile(issuingUser, receivingUser) {
    return this.httpClient.post(
      this.urlMetrics + '/metrics/register-view-profile/', { issuingUser, receivingUser }
    );
  }

  registerViewPublication(issuingUser, receivingUser, publication) {
    return this.httpClient.post(
      this.urlMetrics + '/metrics/register-view-publication/', { issuingUser, receivingUser, publication }
    );
  }

  registerSubscribedByPublication(issuingUser, receivingUser, publication) {
    return this.httpClient.post(
      this.urlMetrics + '/metrics/register-subscribed-by-publication/', { issuingUser, receivingUser, publication }
    );
  }

  registerFollowedByPublication(issuingUser, receivingUser, publication) {
    return this.httpClient.post(
      this.urlMetrics + '/metrics/register-followed-by-publication/', { issuingUser, receivingUser, publication }
    );
  }

  registerViewProfileByPublication(issuingUser, receivingUser, publication) {
    return this.httpClient.post(
      this.urlMetrics + '/metrics/register-view-profile-by-publication/', { issuingUser, receivingUser, publication }
    );
  }

  getQuotizationSubscription(idProfile: string) {
    return this.httpClient.get(
      this.urlSubscription + '/subscription/quotation-subscription/' + idProfile
    );
  }

  getQuotizationChatSubscription(idProfile: string) {
    return this.httpClient.get(
      this.urlSubscription + '/subscription/quotation-subscription-chat/' + idProfile
    );
  }

  changeImageProfile(payload, token?) {
    let params = {};
    if (token) {
      params = { token };
    }
    return this.httpClient.put(
      this.urlUser + '/user/image-profile',
      payload,
      { params }
    ).pipe(
      map((response: any) => {
        this.authService.setUser(response);
        return response;
      })
    );
  }


  deactivateUserActiveById() {
    return this.httpClient.put(this.urlUser + '/user/deactivate-user', {});
  }

  changeIncognito(payload) {
    return this.httpClient.put(this.urlUser + '/user/incognito', payload);
  }

  updateLanguage(lang) {
    return this.httpClient.put(this.urlUser + '/user/update-language', { lang });
  }

  updatePriceMeeting(payload) {
    return this.httpClient.put(this.urlUser + '/user/update-price-meeting', payload);
  }

  changePrivateProfile(payload) {
    return this.httpClient.put(this.urlUser + '/user/private', payload);
  }

  getMySubscriptions() {
    this.httpClient.get(this.urlSubscription + '/subscription/subscriptions').toPromise()
      .then((response: any) => {
        this.subscribers.next(response);
      });
  }

  getModelSubscriptions(modelId) {
    return this.httpClient.get(this.urlSubscription + '/subscription/subscriptions-model/'+modelId);
  }

  getMyChatSubscriptions() {
    this.httpClient.get(this.urlSubscription + '/subscription/chat-subscriptions').toPromise()
      .then((response: any) => {
        this.chatSubscribers.next(response);
      });
  }

  getMySubscribers() {
    return this.httpClient.get(this.urlSubscription + '/subscription/subscribers');
  }

  subscribeUser(subscription) {
    return this.httpClient.post(
      this.urlSubscription + '/subscription/subscribe',
      subscription
    ).pipe(
      map((response: any) => {
        this.subscribers.next(response);
        return response;
      })
    );
  }

  subscribeUserChat(subscription) {
    return this.httpClient.post(
      this.urlSubscription+ '/subscription/subscribe-chat',
      subscription
    ).pipe(
      map((response: any) => {
        this.chatSubscribers.next(response);
        return response;
      })
    );
  }

  isSubscribe(userId) {
    return this.httpClient.get(
      this.urlSubscription + '/subscription/is-subscribe/' + userId
    );
  }

  isSubscribeToChat(userId) {
    return this.httpClient.get(
      this.urlSubscription + '/subscription/is-subscribe-chat/' + userId
    );
  }


  unsubscribe(userSellerId) {
    return this.httpClient
      .put(this.urlSubscription + '/subscription/unsubscribe', { userSellerId })
      .pipe(
        map(
          (response: any) => {
            this.subscribers.next(response);
        })
      );
  }

  getSubscriptions() {
    return this.$subscribers;
  }

  getChatSubscriptions() {
    return this.$chatSubscribers;
  }

  changeImageCover(payload, token?) {
    let params = {};
    if (token) {
      params = { token };
    }
    return this.httpClient.put(
      this.urlUser + '/user/image-cover',
      payload,
      { params }
    ).pipe(
      map((response: any) => {
        this.authService.setUser(response);
        return response;
      })
    );
  }

  updateUser(payload) {
    return this.httpClient.put(this.urlUser + '/user/update', payload).pipe(
      map((response: any) => {
        this.authService.setUser(response);
        return response;
      })
    );
  }

  updatePriceForSubscription(price, token?) {
    let params = {};
    const body = { price };

    if (token) {
      params = { token };
    }
    return this.httpClient.put(
      this.urlUser + '/user/update-price-subscription',
      body,
      { params }
    );
  }

  updatePriceForChatSubscription(price, token?) {
    let params = {};
    const body = { price };

    if (token) {
      params = { token };
    }
    return this.httpClient.put(
      this.urlUser + '/user/update-price-chat-subscription',
      body,
      { params }
    );
  }

  follow(payload) {
    return this.httpClient
      .post(this.urlUser + '/user/follow', payload)
      .pipe(
        map((response: any) => {
          this.followers.next(response.sellersFollowing);
        })
      );
  }

  unfollow(payload) {
    return this.httpClient
      .put(this.urlUser + '/user/unfollow', payload)
      .pipe(
        map((response: any) => {
          this.followers.next(response.sellersFollowing);
        })
      );
  }

  getSellersFollowing() {
    this.httpClient
      .get(this.urlUser + '/user/sellers-following')
      .toPromise()
      .then((response: any) => {
        if (response?.sellersFollowing) {
          this.followers.next(response.sellersFollowing);
        }

      });
  }

  getMyFollowing() {
    return this.$followers;
  }

  getSellerFollowers(sellerId: string) {
    return this.httpClient.get(
      this.urlUser + '/user/seller-followers/' + sellerId
    );
  }

  getUserAllIFollowers(id: string) {
    return this.httpClient.get(
      this.urlUser+ '/user/user-all-i-followers/' + id
    );
  }
  getAllSuscribersWithState(id: string) {
    return this.httpClient.get(
       this.urlSubscription + '/subscription/subscriptions-model-whit-states/' + id
    );
  }

  getAllFollowersWithState(id: string) {
    return this.httpClient.get(
      this.urlUser + '/user/seller-followers-whit-states/' + id
    );
  }

  getSellerFollowersList(sellerId: string) {
    return this.httpClient.get(
      this.urlUser + '/user/seller-followers-list/' + sellerId
    );
  }

  //revisar por que no esta en el back de webxy api
  setService(service) {
    return this.httpClient
      .post(this.env.apiAuth + '/users/enable-service', service);
  }
  //revisar por que no esta en el back de webxy api
  getGeneratorServices(userId) {
    return this.httpClient
      .get(this.env.apiAuth + '/users/services/'+userId);
  }

  getSummary() {
    return this.httpClient.get(this.urlUser + '/user/summary-menu');
  }

  getMetricsByProfile() {
    return this.httpClient
      .get(this.urlMetrics + '/metrics/metrics-profile');
  }

  getRewviewsModel(modelId, page, size) {
    const params = {
      page, size
    };

  return this.httpClient.get(this.urlUser + '/user/reviews-model/' + modelId, { params });
  }


  createState(state) {
    return this.httpClient
      .post(this.urlState + '/state/create-state', state);
  }

   deleteState(id) {
    return this.httpClient
      .delete(this.urlState + '/state/delete-state/'+id);
  }


  getUsersStates() {
    return this.httpClient
      .get(this.urlState + '/state/get-users-states');
  }


    getMyStates() {
    return this.httpClient
      .get(this.urlState + '/state/my-states');
  }

  getUserStates(userId) {
    return this.httpClient
      .get(this.urlState + '/state/get-user-states/' + userId);
  }


  likeState(id: string) {
    return this.httpClient.put(this.urlState +`/state/like-state/${id}`, {});
  }

  dislikeState(id: string) {
    return this.httpClient.put(this.urlState +`/state/dislike-state/${id}`, {});
  }

  seeState(id: string) {
    return this.httpClient.put(this.urlState +`/state/see-state/${id}`, {});
  }

   getStatesByIds(statesId: string[]) {
    let params = {};
    if (statesId) {
      params = { statesId };
    }
    return this.httpClient.get(this.urlState + `/state/get-states-list`, { params });
  }

  //aqui
  excludePublication(publicationId) {
    return this.httpClient.put(this.urlUser + `/user/exclude-publication`, { publicationId });
  }

  report(profileId, reason, publicationId?) {
    return this.httpClient.post(this.urlReport + `/report`, { profile: profileId, reason, publication: publicationId });
  }

  getReasonsReport() {
    return this.httpClient.get(this.urlReport+ `/reasons`);
  }
  getReportByProfileLength(id) {
    return this.httpClient.get(this.urlReport+ '/reports-profile-length/'+id);
  }

  //aun no se como aplicar esto en las consultas
  excludeProfile(profileId) {
    return this.httpClient.put(this.urlBlackList + `/exclude-profile`, { profileId });
  }

  generatorReady(token: string) {
    return this.httpClient.put(this.urlUser + `/user/generator-ready?token=` + token, {});
  }

  getTops() {
    return this.httpClient.get(this.urlUser +`/user/get-top`);
  }

  //verificar si esta en proceso el cambio de perfil
  getDocumentByProfile() {
    return this.httpClient.get(this.urlUser + '/user/document-register-profile');
  }

  //metodo de pago externo

  getAllPaymentMethodModel(id) {
    return this.httpClient.get(`${this.urlModelExternalPayment}/model-external-payment/all-payment-model-methods`+'/' + id);
  }

  getMyPaymentMethod() {
    return this.httpClient.get(`${this.urlModelExternalPayment}/model-external-payment/my-payment-model-methods`);
  }

  createExternalPaymentMethodModels(name, content, disabled) {
    // eslint-disable-next-line max-len
    return this.httpClient.post(this.urlModelExternalPayment + '/model-external-payment/create-payment-methods', { name, content, disabled });
  }

  deleteExternalPaymentMethodModel(_id) {
    return this.httpClient.delete(`${this.urlModelExternalPayment}/model-external-payment/delete-payment-method`+'/' + _id);
  }

  createPayExternal(userClientId, userSellerId, modelPaymentMethod, amount, reference, capture, contextId, type) {
    // eslint-disable-next-line max-len
    return this.httpClient.post(this.urlModelExternalPayment + '/model-external-payment/create-payment-external', {userClientId, userSellerId, modelPaymentMethod, amount, reference, capture, contextId, type});
  }

  getReferenceExternalPayment(id) {
    return this.httpClient.get(`${this.urlModelExternalPayment}/model-external-payment/payment-register` + '/' + id);
  }

  changeStatusExternalPay(id,approved) {
    return this.httpClient.post(this.urlModelExternalPayment + '/model-external-payment/change-status-payment', { id,approved });
  }

  reportScamExternalPayment(userId, payId) {
    return this.httpClient.post(this.urlModelExternalPayment + '/model-external-payment/report-scam', { userId, payId });
  }
  //metodo de pago externo


  getSubscriptionActiveClient() {
    this.httpClient.get(this.urlSubscription + '/subscription/get-subscription-to-platform').subscribe((subscription: any) => {

      if (subscription) {
        this.layoutService.setSubscriptionPlatform(subscription);
      } else {
        this.layoutService.setSubscriptionPlatform(null);
      }
    });
  }

  getSubscriptionPlataformUser() {
    return this.httpClient.get(this.urlSubscription + '/subscription/get-subscription-to-platform');
  }

   getPriceSubByPlataformLustList() {
     return this.httpClient.get(this.urlSubscription + '/subscription/get-price-subs-by-plataform');
   }
  subscribeToPlatformByInternatFound() {
    return this.httpClient.post(this.urlSubscription + '/subscription/subscribe-platform', {}).pipe(
      map((subscription: any) => {
        if (subscription) {
          this.layoutService.setSubscriptionPlatform(subscription);
        }
        return subscription;
      })
    );
  }

}
