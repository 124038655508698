import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService,DataComponent } from '../../services/layout/layout.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { ToastService } from '../../services/toast/toast.service';
import { MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountsService } from '../../services/accounts/accounts.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-modal-subs-follow',
  templateUrl: './modal-subs-follow.component.html',
  styleUrls: ['./modal-subs-follow.component.scss'],
})
export class ModalSubsFollowComponent implements OnInit, OnDestroy {

  subsDataLayout: Subscription;
  data: any;
  title: string;
  isModalSubscribeToChat = false;
  isModalOpen = false;
  messageSubscription: any;
  userIsSubscribe: boolean;
  userIsSubscribeToChat: boolean;
  isModalReport = false;
  priceForSubscription = 0;
  priceForChatSubscription = 0;
  myFunds = 0;
  myId = '';
  userId = '';
  userType = '';
  imageProfile: any;
  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private userService: UsersService,
    private toastService: ToastService,
    private menuController: MenuController,
    private translateService: TranslateService,
    private accountsService: AccountsService,
    private authService: AuthServiceService,
    private transactionService: TransactionsService
  ) { }

  ngOnInit() {

    this.myId = this.authService.getLocalStorage('userId');
    this.userType = this.authService.getLocalStorage('userType');

    this.accountsService.getMyAccount().subscribe(

      (res: any) => {

        this.myFunds = res.amount;
      },
      (error) => console.error(error)
    );
    this.subsDataLayout = this.layoutService.getDataModalSubsFollow()
      .subscribe((val: any) => {

        if (val.type === 'followers') {
          this.title = 'PROFILE.FOLLOWERS';
        } if (val.type === 'subscriptions') {
          this.title = 'PROFILE.FOR_SUSCRIBERS';
        }
        this.data = val.data;
      });
    if (this.data.userId) {
      this.userService.getUser(this.data.userId).subscribe((val: any) => {
        this.imageProfile = val.imageProfile.imageUrl;
      });

    }
  }

  ngOnDestroy(): void {
    this.subsDataLayout.unsubscribe();
  }

  close() {
    this.layoutService.setShowModalSubsFollow(false);
  }

  navigateTo(id) {
    this.userService.getDataProfile(id).subscribe((val: any) => {
      if (val.user.type.type === 1) {
      this.router.navigate(['/profile/'+ id ]);
      this.close();
      } else {
        this.toastService.presentToast('PROFILE.CLIENT_NO_PUBLIC_PROFILE', 1500);
      }
    });

  }
    openRight() {
    this.menuController.open('menu2');
  }

  selectChat(id) {
    this.userId = id;
    if (this.myId === id) {
      return;
    }
    this.userService.getDataProfile(id).subscribe((val: any) => {
      if (val.dataUser.type.type === 1) {
        this.getSubscriptionChat(id);
        this.priceSubscription(id);
      }
      if (this.userType === '2') {
      if (val.dataUser.type.type === 2 ) {
        this.toastService.presentToast('GENERAL.YOU_CANNOT_CHAT_WHIT_CLIENT', 1500);
      }
      } if (this.userType === '1') {
         if (val.dataUser.type.type === 2 ) {
       const component: DataComponent = { component: 'chat', contextId: this.userId };
        this.layoutService.setChatRoom(component);
           this.router.navigate(['chat-room'])
           this.close();
      }
      }
    });



  }


  getSubscriptionChat(id) {
    this.userService.isSubscribeToChat(id).subscribe((resp: boolean) => {

      this.userIsSubscribeToChat = resp;
          if (this.userIsSubscribeToChat) {
        const component: DataComponent = { component: 'chat', contextId: this.userId };
        this.layoutService.setChatRoom(component);
        this.router.navigate(['chat-room'])
            this.close();
      } else {
        this.isOpenModalSuscribeToChat();
      }
    });
  }


  isOpenModalSuscribeToChat() {
    this.isModalSubscribeToChat = true;
    this.isModalOpen = true;
     this.messageSubscription = {
      message: this.translateService.instant('MODAL.SUSCRIBE')};
  }

  confirmModal() {
      if (this.isModalSubscribeToChat) {
        this.subscribeToChat(this.userId);
      }
  }

   priceSubscription(id) {
        this.userService
          .getQuotizationSubscription(id)
          .subscribe((res: any) => {
            this.priceForSubscription = res.subscriptionPrice;

            this.userService
              .getQuotizationChatSubscription(id)
              .subscribe((data: any) => {
                this.priceForChatSubscription = data.subscriptionPrice;
                if (!this.priceForChatSubscription) {
                  this.priceForChatSubscription = this.priceForSubscription === 1 ? 1 : this.priceForSubscription / 2;
                }
              });
          });
   }


  subscribeToChat(id) {
    const transaction = {
      userClientId: this.myId,
      userSellerId: id,
      aprovedClient: true,
      aprovedSeller: true,
      amount: this.priceForChatSubscription,
      type: 1,
    };
    this.isModalOpen = false;
    this.transactionService
      .registerTransactionForUserIds(transaction)
      .subscribe(
        (res: any) => {
          const subscription = {
            userClientId: this.myId,
            userSellerId: id,
            transaction: res._id,
            price: this.priceForChatSubscription,
            status: 1,
          };
          this.userService.subscribeUserChat(subscription).subscribe(() => {
            this.userIsSubscribeToChat = true;
            this.layoutService.setUpdateStatus();
            const req = {
              url: this.router.url.substring(0, 8).concat('/subscribe'),
              method: 'PUT',
            } as HttpRequest<string>;
            this.toastService.presentToastMiddleCheck(req);
            const component: DataComponent = { component: 'chat', contextId: id};
            this.layoutService.setChatRoom(component);
           this.router.navigate(['chat-room'])
          });
        },
        (error) => {
          console.error(error);
        }
      );

    /**/
  }

    externalPaymentChat() {
    const payload = {
      userId : this.userId,
      contextId: this.userId,
      title: 'suscription-chat',
      amount: this.priceForChatSubscription,
      typePayment: 'payment'
    };
    this.layoutService.setShowModalPaymentMethods(true);
    this.layoutService.setDataModalPaymentMethods(payload);
  }

}
