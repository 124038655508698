/* eslint-disable max-len */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { LayoutService } from '../../services/layout/layout.service';
import { UsersService } from '../../services/users/users.service';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastService } from '../../services/toast/toast.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { PublicationService } from '../../services/publication/publication.service';
import {
  Publication,
  PublicationExtends,
} from 'src/app/core/models/publication.model';
import { SocketService } from '../../services/socket/socket.service';


@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {

  @ViewChild('inputGalleryImage') inputGalleryImage: ElementRef;

  addMethod = false;
  addNewPayment = false;
  paymentMethods: any;
  showDetailPayment = false;
  registerPayment = false;
  billingPayment = false;
  modalBillingPayment = false;
  modalPaymenExternal = false;
  detailPayment: any;
  billing: any;
  userId = '';
  myId = '';
  imageForEdit: ArrayBuffer | string = '';
  isEditImage = false;
  subDataUserId: Subscription;
  imagesLoad: any = null;
  descriptionPay: any;
  price: any;
  contextId: any;
  type: any;

  formCreatePaymentMethod = new FormGroup({
    name: new FormControl('',[Validators.required,]),
    content: new FormControl('',[Validators.required]),
    disabled: new FormControl(false)
  });

    formRegisterPay = new FormGroup({
    ref: new FormControl('', [Validators.required, Validators.maxLength(25)]),
    amount: new FormControl('', [Validators.required]),
    images: new FormControl('',[Validators.required]),
  });


   loading: HTMLIonLoadingElement;
  constructor(
    private layoutService: LayoutService,
    private usersService: UsersService,
    private toastService: ToastService,
    private authService: AuthServiceService,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private publicationService: PublicationService,
    private socketService: SocketService
  ) { }

    get formRegisterPayImages() {
    return this.formRegisterPay.get('images').value;
  }

  ngOnInit() {
    this.typeUser();
    this.myId = this.authService.getLocalStorage('userId');
    this.subDataUserId = this.layoutService.getDataModalPaymentMethods().subscribe((val: any) => {

      if(val?.typePayment === 'payment'){
      this.userId = val.userId;
      this.descriptionPay = val.title;
      this.type = val.title;
      this.contextId = val.contextId;
        this.formRegisterPay.get('amount').setValue(val.amount);
        this.getAllPaymentMethodModel();
      } else if (val?.typePayment === 'billing') {
        if (val.userSellerId._id === this.myId) {
          this.userId =  val.userSellerId._id;
        }

        this.billing = val;
        this.addMethod = true;
        this.addNewPayment = false;
        this.registerPayment = false;
        this.billingPayment = true;
      }
    });


  }

  getAllPaymentMethodModel() {
    if (this.userId == this.myId) {
      this.usersService.getMyPaymentMethod().subscribe((val: any) => {
        this.paymentMethods = val;
      });
    }else{
      this.usersService.getAllPaymentMethodModel(this.userId).subscribe((val: any) => {
        this.paymentMethods = val;
      });
    }

  }



  isMyProfile() {
    return this.userId === this.myId;
  }


  addNewMethod() {
    this.addMethod = true;
    this.addNewPayment = true;
  }

  ngOnDestroy(): void {
    this.subDataUserId.unsubscribe();
  }

  typeUser() {
    return this.usersService.getMyTypeUser();
  }

    clickInputGalleryImage() {
    this.inputGalleryImage.nativeElement.click();
  }


  close() {
    this.layoutService.setShowModalPaymentMethods(false);
    this.modalBillingPayment = false;
    this.modalPaymenExternal = false;
  }
  selectPayment(i) {
    this.detailPayment = this.paymentMethods[i];
    this.addMethod = true;
    this.showDetailPayment = true;
  }

  deletePaymentMethod() {
    const payMet = this.detailPayment._id;
    this.usersService.deleteExternalPaymentMethodModel(payMet).subscribe(val => {
      this.toastService.presentToast('GENERAL.YOU_HAVE_DELETE_PAYMENT_METHOD', 1500);
      this.close();
    });
  }

  confirmPay(){
    this.showDetailPayment = false;
    this.registerPayment = true;
  }

    getImageFromGallery(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const inputElement = event.target as HTMLInputElement;
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 15 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_IMAGE', 3000);
        inputElement.value = '';
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.imageForEdit = reader.result;
          this.isEditImage = true;
        });
        reader.readAsDataURL(file);
      }

    }
    }
    showLoading(type: number) {
    let message;
    switch (type) {
      case 1:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;

      default:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;
    }
    const options = {
      message,
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

    uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(1);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.publicationService.saveImageRegister(formData).subscribe((res: any) => {
         const newImage = {
           id: res._id,
          url: res.imageUrl,
         };
        this.formRegisterPay.get('images').setValue(newImage);
        this.imagesLoad =newImage ;

        this.loading.dismiss();
        this.isEditImage = false;
      },
        (err) => {
          this.loading.dismiss();
        });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }

  approvedPayment() {
    this.usersService.changeStatusExternalPay(this.billing._id, true).subscribe(val => {
      this.close();
      this.toastService.presentToast('GENERAL.RELEASED_ACTION', 3000);
      this.sendNotification(this.billing.userClientId._id, this.billing.userSellerId._id, this.billing._id, true);
    });
  }

  reportPayment() {
    this.usersService.reportScamExternalPayment(this.myId, this.billing._id).subscribe(val => {

    });
    }

  translateText(text) {

    let response;

    switch (text) {
      case 'suscription':
        response = 'GENERAL.SUSCRIPION';
        break;
      case 'suscription-chat':
        response = 'GENERAL.CHAT_SUB';
        break;
      case 'full-movie':
        response = 'GENERAL.FULL_MOVIE';
        break;
      case 'service-menu':
        response = 'GENERAL.SERVICE_MENU';
        break;
    }
    return response;
  }

  registerPay() {
    const formValue = this.formRegisterPay.value;

    // eslint-disable-next-line max-len
    this.usersService.createPayExternal(
      this.myId,
      this.userId,
      this.detailPayment._id,
      formValue.amount,
      formValue.ref,
      formValue.images.id,
       this.contextId,
      this.descriptionPay
    ).subscribe((res: any) => {
      this.toastService.presentToast('FINANCE.PAYMENT_REFERENCE_BE_SEND', 3000);
      this.close();
      this.sendNotification(this.userId,this.myId, res._id);
    });
  }

  sendNotification(userReceiver, userSender, contextId, isApproved?) {
    const notification = {
      userReceiver,
      userSender,
      type:'external-pay',
      message: 'BUTTON.PAY_EXTERNAL',
      isApproved,
      contextId
    };
    this.socketService.sendNotification(notification);
  }

  onDeleteMedia(mediaSelected: PublicationExtends): void {
    this.imagesLoad = null;
    this.deleteMedia(mediaSelected.id, 'image');
  }


  addNewPaymentMethods() {
    const { name, content, disabled } = this.formCreatePaymentMethod.value;
    this.usersService.createExternalPaymentMethodModels(name, content, disabled).subscribe(val => {
      this.toastService.presentToast('GENERAL.YOU_HAVE_CREATE_PAYMENT_METHOD', 1500);
      this.close();
  });
  }

   private deleteMedia(mediaId, type) {
    this.publicationService.deleteMedia(mediaId, type).subscribe((res) => {

    });
  }
}
