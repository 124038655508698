import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  env = environment;
  private urlPaymentMethods = this.env.API_SERVICE_LL_PAYMENT_METHOD;
  private urlAccount = this.env.API_SERVICE_LL_ACCOUNT
  constructor(private httpClient: HttpClient) { }

  getMyAccount() {
    return this.httpClient.get(this.urlAccount + '/account/my-account/');
  }

  getUserAccount(userId) {
    return this.httpClient.get(this.urlAccount + '/account/number-account/' + userId);
  }

  getMyCash() {
    return this.httpClient.get(this.urlAccount + '/account/my-cash');
  }

  getPaymentMethods() {
    return this.httpClient.get(this.urlPaymentMethods + '/payment-method');
  }
}
