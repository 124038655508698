import {
  Component, OnInit, Input,Output, EventEmitter
} from '@angular/core';
@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {

  @Input() title = '';
  @Input() text = '';
  @Input() image: string;

  @Output() showModal = new EventEmitter<boolean>();
  constructor(

  ) { }

  ngOnInit() {
  }

  close() {
    this.showModal.emit(false);
  }

}
