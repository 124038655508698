/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';

export interface DataComponent {
  component:
  | 'recomendation'
  | 'chat'
  | 'folder-column'
  | 'wish-detail'
  | 'billing-detail'
  | 'my-products';
  contextId: string;
  reload?: boolean;
  action?: string;
  wishRef?: any;
  offertRef?: any;
  billingRef?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  initialComponent: DataComponent = {
    component: 'recomendation',
    contextId: '',
  };

  isBeforeChat = false;

  private componentSelect = new BehaviorSubject<DataComponent>(
    this.initialComponent
  );
  private componentSelect$ = this.componentSelect.asObservable();


  private chatRoom = new BehaviorSubject<DataComponent>(
    this.initialComponent
  );
  private chatRoom$ = this.chatRoom.asObservable();

  private newPublicationComponent = new BehaviorSubject<DataComponent>(
    this.initialComponent
  );
  private newPublicationComponent$ = this.newPublicationComponent.asObservable();

  private dataFrameImage = new BehaviorSubject<any>(null);
  private dataFrameImage$ =
    this.dataFrameImage.asObservable();

  private showModalFrameImage = new BehaviorSubject<boolean>(false);
  private showModalFrameImage$ =
    this.showModalFrameImage.asObservable();

  private showModalCommentFrame = new BehaviorSubject<boolean>(false);
  private showModalCommentFrame$ =
    this.showModalCommentFrame.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  quantityComments = 0;

   private dataCommentImage = new BehaviorSubject<any>(null);
  private dataCommentImage$ =
    this.dataCommentImage.asObservable();

  private showComponentNewPublication = new BehaviorSubject<boolean>(false);
  private showComponentNewPublication$ =
    this.showComponentNewPublication.asObservable();

  private showModalBlockAds = new BehaviorSubject<boolean>(false);
  private showModalBlockAds$ =
    this.showModalBlockAds.asObservable();

    private showModalBlockPop = new BehaviorSubject<boolean>(false);
  private showModalBlockPop$ =
    this.showModalBlockPop.asObservable();


  private showModalClientInfo = new BehaviorSubject<boolean>(false);
  private showModalClientInfo$ =
    this.showModalClientInfo.asObservable();

  private showModalDataClientInfo = new BehaviorSubject<any>(null);
  private showModalDataClientInfo$ =
    this.showModalDataClientInfo.asObservable();

  private newPublication = new BehaviorSubject<boolean>(false);
  private newPublication$ =
    this.newPublication.asObservable();

  private aceppteTermsAndConditions = new BehaviorSubject<boolean>(false);
  private aceppteTermsAndConditions$ =
    this.aceppteTermsAndConditions.asObservable();

  private showButtonApTsConds = new BehaviorSubject<boolean>(false);
  private showButtonApTsConds$ =
    this.showButtonApTsConds.asObservable();

  private showComponentNewState = new BehaviorSubject<any>(null);
  private showComponentNewState$ =
    this.showComponentNewState.asObservable();

  private resetCalculatorServiceModels = new BehaviorSubject<any>(null);
  private resetCalculatorServiceModels$ =
    this.resetCalculatorServiceModels.asObservable();

  private showStateModal = new BehaviorSubject<any>([]);
  private showStateModal$ =
    this.showStateModal.asObservable();

  private usersAllViewStates = new BehaviorSubject<string[]>([]);
  private usersAllViewStates$ =
    this.usersAllViewStates.asObservable();

  private stateOpeningSequence = new BehaviorSubject<[][]>([]);
  private stateOpeningSequence$ =
    this.stateOpeningSequence.asObservable();

  private showComponentNewWish = new BehaviorSubject<boolean>(false);
  private showComponentNewWish$ =
    this.showComponentNewWish.asObservable();

  private showComponentMovieFrameDetail = new BehaviorSubject<boolean>(false);
  private showComponentMovieFrameDetail$ =
    this.showComponentMovieFrameDetail.asObservable();

  private dataComponentMovieFrameDetail = new BehaviorSubject<any>(null);
  private dataComponentMovieFrameDetail$ =
    this.dataComponentMovieFrameDetail.asObservable();

  private showComponentServiceModal = new BehaviorSubject<any>(null);
  private showComponentServiceModal$ =
    this.showComponentServiceModal.asObservable();

  private showComponentAttributes = new BehaviorSubject<any>({show:false});
  private showComponentAttributes$ =
    this.showComponentAttributes.asObservable();

  private dataTitleAddPublication = new BehaviorSubject<any>(null);
  private dataTitleAddPublication$ =
    this.dataTitleAddPublication.asObservable();

  private dataWishComponentNewPublication = new BehaviorSubject<any>(null);
  private dataWishComponentNewPublication$ =
    this.dataWishComponentNewPublication.asObservable();

  private dataWishComponentNewMovie = new BehaviorSubject<any>(null);
  private dataWishComponentNewMovie$ =
    this.dataWishComponentNewMovie.asObservable();

  private dataWishComponentNewWish = new BehaviorSubject<any>(null);
  private dataWishComponentNewWish$ =
    this.dataWishComponentNewWish.asObservable();

  private setDataDeleteImage = new BehaviorSubject<any>(null);
  private setDataDeleteImage$ =
    this.setDataDeleteImage.asObservable();

  private activeSpliceDeleteImage = new BehaviorSubject<boolean>(false);
  private activeSpliceDeleteImage$ =
    this.activeSpliceDeleteImage.asObservable();

  private showComponentNewOffer = new BehaviorSubject<boolean>(false);
  private showComponentNewOffer$ =
    this.showComponentNewOffer.asObservable();

  private dataNewOffer = new BehaviorSubject<any>(null);
  private dataNewOffer$ =
    this.dataNewOffer.asObservable();

  private dataBillingDetail = new BehaviorSubject<any>(null);
  private dataBillingDetail$ =
    this.dataBillingDetail.asObservable();

  private showModalBillingDetail = new BehaviorSubject<boolean>(null);
  private showModalBillingDetail$ =
    this.showModalBillingDetail.asObservable();

  private dataBillTicketDetail = new BehaviorSubject<any>(null);
  private dataBillTicketDetail$ =
    this.dataBillTicketDetail.asObservable();

  private dataExtraTicket = new BehaviorSubject<any>(null);
  private dataExtraTicket$ =
    this.dataExtraTicket.asObservable();

  private showModalBillTicketDetail = new BehaviorSubject<boolean>(null);
  private showModalBillTicketDetail$ =
    this.showModalBillTicketDetail.asObservable();


  private showModalPaymentMethods = new BehaviorSubject<boolean>(null);
  private showModalPaymentMethods$ =
    this.showModalPaymentMethods.asObservable();

  private dataModalPaymentMethods = new BehaviorSubject<any>(null);
  private dataModalPaymentMethods$ =
    this.dataModalPaymentMethods.asObservable();


  private showModalPaymentSubscriptionPlataform = new BehaviorSubject<boolean>(null);
  private showModalPaymentSubscriptionPlataform$ =
    this.showModalPaymentSubscriptionPlataform.asObservable();

  private dataModalPaymentSubscriptionPlataform = new BehaviorSubject<any>(null);
  private dataModalPaymentSubscriptionPlataform$ =
    this.dataModalPaymentSubscriptionPlataform.asObservable();

  private showComponentComment = new BehaviorSubject<any>(null);
  private showComponentComment$ =
    this.showComponentComment.asObservable();

  private idMeetingDetail = new BehaviorSubject<string>(null);
  private idMeetingDetail$ =
    this.idMeetingDetail.asObservable();

  private showModalEdiImage = new BehaviorSubject<boolean>(null);
  private showModalEdiImage$ =
    this.showModalEdiImage.asObservable();


  // TODO METRICS
  private showModalMetrics = new BehaviorSubject<boolean>(null);
  private showModalMetrics$ =
    this.showModalMetrics.asObservable();

  private dataModalMetrics = new BehaviorSubject<any>(null);
  private dataModalMetrics$ =
    this.dataModalMetrics.asObservable();
  // TODO METRICS
  private showModalWithdraw = new BehaviorSubject<boolean>(null);
  private showModalWithdraw$ =
    this.showModalWithdraw.asObservable();


  private dataModalEditImage = new BehaviorSubject<any>(null);
  private dataModalEditImage$ =
    this.dataModalEditImage.asObservable();

  private showModalSubsFollow = new BehaviorSubject<boolean>(null);
  private showModalSubsFollow$ =
    this.showModalSubsFollow.asObservable();

  private dataModalMaxDonwloadPay= new BehaviorSubject<any>(null);
  private dataModalMaxDonwloadPay$ =
    this.dataModalMaxDonwloadPay.asObservable();

  private notificationDownloadMovie= new BehaviorSubject<any>(null);
  private notificationDownloadMovie$ =
    this.notificationDownloadMovie.asObservable();

    private showModalMaxDonwloadPay = new BehaviorSubject<boolean>(null);
  private showModalMaxDonwloadPay$ =
    this.showModalMaxDonwloadPay.asObservable();


  private dataModalSubsFollow = new BehaviorSubject<any>(null);
  private dataModalSubsFollow$ =
    this.dataModalSubsFollow.asObservable();

    private dataModalOnline = new BehaviorSubject<any>(null);
  private dataModalOnline$ =
    this.dataModalOnline.asObservable();

      private dataEditPublication = new BehaviorSubject<any>(null);
  private dataEditPublication$ =
    this.dataEditPublication.asObservable();

    private showModalOnline = new BehaviorSubject<boolean>(null);
  private showModalOnline$ =
    this.showModalOnline.asObservable();

  private dataWishDetailModal = new BehaviorSubject<any>(null);
  private dataWishDetailModal$ =
    this.dataWishDetailModal.asObservable();

  private showModalWishDetail = new BehaviorSubject<boolean>(null);
  private showModalWishDetail$ =
    this.showModalWishDetail.asObservable();

  private showModalInformation = new BehaviorSubject<boolean>(null);
  private showModalInformation$ =
    this.showModalInformation.asObservable();

  private updateSummary = new BehaviorSubject<boolean>(null);
  private updateSummary$ =
    this.updateSummary.asObservable();

  private showModalAddNewVideos =new BehaviorSubject<boolean>(null);
  private showModalAddNewVideos$ =
    this.showModalAddNewVideos.asObservable();

  private newMovie =new BehaviorSubject<boolean>(null);
  private newMovie$ =
    this.newMovie.asObservable();

  private parameterSearch =new BehaviorSubject<string>(null);
  private parameterSearch$ =
    this.parameterSearch.asObservable();

  private mettingSelect = new BehaviorSubject<any>(null);
  private mettingSelect$ =
    this.mettingSelect.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  meetingIdTimeOut: any[] = [];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  meetInRoomChannel: any = null;

  private onCall = new BehaviorSubject<boolean>(false);
  private onCall$ =
    this.onCall.asObservable();

  private subscriptionPlatform = new BehaviorSubject<any>(null);
  private subscriptionPlatform$ =
    this.subscriptionPlatform.asObservable();

  private updateListWish = new BehaviorSubject<boolean>(null);
  private updateListWish$ =
    this.updateListWish.asObservable();

  constructor() { }

  cleanMeetingIdTimeOut() {
    this.meetingIdTimeOut.forEach(id => {
      clearTimeout(id);
    });
  }

  setMeetingsToday(meets) {
    for (const meet of meets) {
      if (meet.status.status === 2) {
        //10 minutos antes de iniciar
        const time = new Date(new Date(meet.start).setMinutes(new Date(meet.start).getMinutes() - 10)).getTime() - new Date().getTime();

        const meetingIdTimeOut = setTimeout(() => {
          this.setMeetingSelect(meet);
        }, time);

        const closeTime = new Date(meet.end).getTime() - new Date().getTime();
        const meetingIdTimeOutClose = setTimeout(() => {
          this.setMeetingSelect(null);
        }, closeTime);

        this.meetingIdTimeOut.push(meetingIdTimeOut);
        this.meetingIdTimeOut.push(meetingIdTimeOutClose);
      }
    }
  }

  setComponent(component: DataComponent) {
    this.componentSelect.next(component);
  }


  getComponent() {
    return this.componentSelect$;
  }

  setComponentNewPublication(component: any) {
    this.newPublicationComponent.next(component);
  }


  getComponentPublication() {
    return this.newPublicationComponent$;
  }

   setChatRoom(component: DataComponent) {
    this.chatRoom.next(component);
  }


  getChatRoom() {
    return this.chatRoom$;
  }

  getDataTitleAddPublication() {
    return this.dataTitleAddPublication$;
  }

  setShowModalInformation(data: boolean) {
   this.showModalInformation.next(data);
  }

  getShowModalInfomation() {
    return this.showModalInformation$;
  }
 setShowModalOnline(data: boolean) {
   this.showModalOnline.next(data);
  }

  getShowModalOnline() {
    return this.showModalOnline$;
  }

  setDataModalOnline(data: any) {
   this.dataModalOnline.next(data);
  }

  getDataModalOnline() {
    return this.dataModalOnline$;
  }

  setResetCalculatorServiceModel(data: any) {
   this.resetCalculatorServiceModels.next(data);
  }

  getResetCalculatorServiceModel() {
    return this.resetCalculatorServiceModels$;
  }

  setShowModalPaymentMethods(data: boolean) {
    this.showModalPaymentMethods.next(data);
  }

  getShowModalPaymentMethods() {
    return this.showModalPaymentMethods$;
  }

  setShowModalMetrics(data: boolean) {
    this.showModalMetrics.next(data);
  }

  getShowModalMetrics() {
    return this.showModalMetrics$;
  }

  setDataEditPublication(data: any) {
    this.dataEditPublication.next(data);
  }

  getDataEditPublication() {
    return this.dataEditPublication$;
  }

  setDataModalMetrics(data: any) {
    this.dataModalMetrics.next(data);
  }

  getDataModalMetrics() {
    return this.dataModalMetrics$;
  }

  setDataModalPaymentMethods(data: any) {
    this.dataModalPaymentMethods.next(data);
  }

  getDataModalPaymentMethods() {
    return this.dataModalPaymentMethods$;
  }
  setShowModalMovieFrameDetail(data: boolean) {
    this.showComponentMovieFrameDetail.next(data);
  }

  getShowModalMovieFrameDetail() {
    return this.showComponentMovieFrameDetail$;
  }
  setShowshowModalWithdraw(data: boolean) {
    this.showModalWithdraw.next(data);
  }

  getShowshowModalWithdraw() {
    return this.showModalWithdraw$;
  }

  setDataModalMovieFrameDetail(data: any) {
    this.dataComponentMovieFrameDetail.next(data);
  }

  getDataModalMovieFrameDetail() {
    return this.dataComponentMovieFrameDetail$;
  }

  setShowModalMaxDownloadPay(data: boolean) {
    this.showModalMaxDonwloadPay.next(data);
  }

  getShowModalMaxDownloadPay() {
    return this.showModalMaxDonwloadPay$;
  }

  setShowModalClientInfo(data: boolean) {
    this.showModalClientInfo.next(data);
  }

  getShowModalClientInfo() {
    return this.showModalClientInfo$;
  }
 setShowModalDataClientInfo(data: any) {
    this.showModalDataClientInfo.next(data);
  }

  getShowModalDataClientInfo() {
    return this.showModalDataClientInfo$;
  }

  setDataNotificationDownloadMovie(data: any) {
    this.notificationDownloadMovie.next(data);
  }

  getDataNotificationDownloadMovie() {
    return this.notificationDownloadMovie$;
  }

  setDataModalMaxDownloadPay(data: any) {
    this.dataModalMaxDonwloadPay.next(data);
  }

  getDataModalMaxDownloadPay() {
    return this.dataModalMaxDonwloadPay$;
  }

  setAceppteTermsAndConditions(data: boolean) {
    this.aceppteTermsAndConditions.next(data);
  }

  getaceppteTermsAndConditions() {
    return this.aceppteTermsAndConditions$;
  }
  setShowButtonApTsConds(data: boolean) {
    this.showButtonApTsConds.next(data);
  }

  getShowButtonApTsConds() {
    return this.showButtonApTsConds$;
  }

  setShowModalPaymentSubsPlataform(data: boolean) {
    this.showModalPaymentSubscriptionPlataform.next(data);
  }

  getShowModalPaymentSubsPlataform() {
    return this.showModalPaymentSubscriptionPlataform$;
  }

  setShowModalBlockAds(data: boolean) {
    this.showModalBlockAds.next(data);
  }

  getShowModalBlockAds() {
    return this.showModalBlockAds$;
  }
  setShowModalBlockPop(data: boolean) {
    this.showModalBlockPop.next(data);
  }

  getShowModalBlockPop() {
    return this.showModalBlockPop$;
  }

  setDataModalPaymentSubsPlataform(data: any) {
    this.dataModalPaymentSubscriptionPlataform.next(data);
  }

  getDataModalPaymentSubsPlataform() {
    return this.dataModalPaymentSubscriptionPlataform$;
  }

  setDataModalSubsFollow(data: any) {
  this.dataModalSubsFollow.next(data);
}

  getDataModalSubsFollow() {
    return this.dataModalSubsFollow$;
  }

   setShowModalSubsFollow(data: any) {
    this.showModalSubsFollow.next(data);
  }

  getShowModalSubsFollow() {
    return this.showModalSubsFollow$;
  }

  setDataTitleAddPublication(data: any) {
    this.dataTitleAddPublication.next(data);
  }

  getActiveDeleteImage() {
    return this.activeSpliceDeleteImage$;
  }

  setDataShowModalAddNewVideos(data: boolean) {
    this.showModalAddNewVideos.next(data);
  }

  getDataShowModalAddNewVideos() {
    return this.showModalAddNewVideos$;
  }
  setNewMovie() {
    this.newMovie.next(true);
    this.newMovie.next(null);
  }

  getNewMovie() {
    return this.newMovie$;
  }

  setShowModalBillTicketDetail(data: boolean) {
    this.showModalBillTicketDetail.next(data);
  }

  getShowModalBillTicketDetail() {
    return this.showModalBillTicketDetail$;
  }

  setDataModalBillTicketDetail(data: any) {
    this.dataBillTicketDetail.next(data);
  }

  getDataModalBillTicketDetail() {
    return this.dataBillTicketDetail$;
  }

  setDataExtraTicket(data: any) {
    this.dataExtraTicket.next(data);
  }

  getDataExtraTicket() {
    return this.dataExtraTicket$;
  }

  setActiveDeleteImage(data: boolean) {
    this.activeSpliceDeleteImage.next(data);
  }

  getDataDeleteImage() {
    return this.setDataDeleteImage$;
  }

  setIdDeleteImage(id: any) {
    this.setDataDeleteImage.next(id);
  }

  setDataBillingDetail(data: any) {
    this.dataBillingDetail.next(data);
  }

  getDataBillingDetail() {
    return this.dataBillingDetail$;
  }

  setShowModelBillingDetail(show: boolean) {
    this.showModalBillingDetail.next(show);

  }

  getShowModalBillingDetail() {
    return this.showModalBillingDetail$;
  }

  setShowComponentComment(show: boolean) {
    this.showComponentComment.next(show);
  }

  getDataWishDetailModal() {
    return this.dataWishDetailModal$;
  }

  setDataWishDetailModal(data: any) {
    this.dataWishDetailModal.next(data);
  }

  getShowModalWishDetail() {
    return this.showModalWishDetail$;
  }

  setShowModalWishDetail(show: boolean) {
    this.showModalWishDetail.next(show);
  }


  getShowComponentComment() {
    return this.showComponentComment$;
  }

  getShowComponentNewPublication() {
    return this.showComponentNewPublication$;
  }

  getShowComponentNewState() {
    return this.showComponentNewState$;
  }

  setShowComponentNewState(show: boolean) {
    this.showComponentNewState.next(show);
  }

  getShowModalCommentFrame() {
    return this.showModalCommentFrame$;
  }

  setShowModalCommentFrame(show: boolean, quantityComments?: number) {
    if (quantityComments) {
      this.quantityComments = quantityComments;
    }
    this.showModalCommentFrame.next(show);
  }

  getShowModalFrameDetail() {
    return this.showModalFrameImage$;
  }

  setShowModalFrameDetail(show: boolean) {
    this.showModalFrameImage.next(show);
  }

  getDataFrameImage() {
    return this.dataFrameImage$;
  }

  setDataFrameImage(data: any) {
    this.dataFrameImage.next(data);
  }

  getShowModalEditImg() {
    return this.showModalEdiImage$;
  }

  setShowModalEditImg(show: boolean) {
    this.showModalEdiImage.next(show);
  }

  getDataModalEditImage() {
    return this.dataModalEditImage$;
  }

  setDataModalEditImage(data: any) {
    this.dataModalEditImage.next(data);
  }

  getDataCommentFrame() {
    return this.dataCommentImage$;
  }

  setDataCommentFrame(data: any) {
    this.dataCommentImage.next(data);
  }

  getShowStateModal() {
    return this.showStateModal$;
  }

  setShowStateModal(states: any) {
    this.showStateModal.next(states);
  }

  setNewUserViewAllStates(userId) {
    const usersAllViewStates = this.usersAllViewStates.getValue();
    if (usersAllViewStates.includes(userId)) {
      return;
    }
    usersAllViewStates.push(userId);
    this.usersAllViewStates.next(usersAllViewStates);
  }

  removeUserViewAllStates(userId) {
    let usersAllViewStates = this.usersAllViewStates.getValue();
    usersAllViewStates = usersAllViewStates.filter(user => user !== userId);
    this.usersAllViewStates.next(usersAllViewStates);
  }

  getNewUserViewAllStates() {
    return this.usersAllViewStates$;
  }

  setStateOpeningSequence(sequences) {
    this.stateOpeningSequence.next(sequences);
  }

  getStateOpeningSequence() {
    return this.stateOpeningSequence$;
  }

  setShowComponentNewPublication(show: boolean) {
    this.showComponentNewPublication.next(show);
  }

  setNewPublication() {
    this.newPublication.next(true);
    this.newPublication.next(false);
  }

  getNewPublication() {
    return this.newPublication$;
  }

  getDataWishComponentNewPublication() {
    return this.dataWishComponentNewPublication$;
  }

  setDataWishComponentNewPublication(data: any) {
    this.dataWishComponentNewPublication.next(data);
  }

  getDataWishComponentNewMovie() {
    return this.dataWishComponentNewMovie$;
  }

  setDataWishComponentNewMovie(data: any) {
    this.dataWishComponentNewMovie.next(data);
  }

  getShowComponentNewWish() {
    return this.showComponentNewWish$;
  }

  getShowComponentServiceModal() {
    return this.showComponentServiceModal$;
  }

  getShowComponentAttributes() {
    return this.showComponentAttributes$;
  }

  setShowComponentAttributes(show: boolean, type: string) {
    this.showComponentAttributes.next({show, type});
  }

  setShowComponentServiceModal(service: any) {
    this.showComponentServiceModal.next(service);
  }

  setShowComponentNewWish(show: boolean) {
    this.showComponentNewWish.next(show);
  }

  getDataWishComponentNewWish() {
    return this.dataWishComponentNewWish$;
  }

  setDataWishComponentNewWish(data: any) {
    this.dataWishComponentNewWish.next(data);
  }

  getShowNewOffer() {
    return this.showComponentNewOffer$;
  }

  setShowNewOffer(show: boolean) {
    this.showComponentNewOffer.next(show);
  }

  getDataNewOffer() {
    return this.dataNewOffer$;
  }

  setDataNewOffer(data: any) {
    this.dataNewOffer.next(data);
  }

  refreshComponent() {
    const component = this.componentSelect.getValue();
    component.reload = true;
    this.setComponent(component);
  }

  refreshChatRoom() {
    const component = this.chatRoom.getValue();
    component.reload = true;
    this.setComponent(component);
  }


  getIdMeetingDetail() {
    return this.idMeetingDetail$;
  }

  setIdMeetingDetail(meetingdId: string) {
    this.idMeetingDetail.next(meetingdId);
  }

  setUpdateStatus() {
    this.updateSummary.next(true);
    this.updateSummary.next(false);
  }

  getUpdateStatus() {
    return this.updateSummary$.pipe(map(
      (update: boolean) => {
        if (update) {
          return update;
        }
      }));
  }

  setUpdateListwish() {
    this.updateListWish.next(true);
    this.updateListWish.next(false);
  }

  getUpdateListwish() {
    return this.updateListWish$.pipe(map(
      (update: boolean) => {
        if (update) {
          return update;
        }
      }));
  }

  getMeetingSelect() {
    return this.mettingSelect$;
  }

  setMeetingSelect(meet) {

    if (this.meetInRoomChannel !== meet || meet == null) {
      this.mettingSelect.next(meet);
    }

  }

  setMeetRoom(channel) {
    if (this.mettingSelect.getValue()?.channel === channel) {
      this.meetInRoomChannel = this.mettingSelect.getValue();
      this.mettingSelect.next(null);
    }
  }

  closeMeetRoom(channel) {
    if (this.meetInRoomChannel.channel === channel) {
      this.mettingSelect.next(this.meetInRoomChannel);
      this.meetInRoomChannel = null;

    }
  }

  setOnCall(onCall: boolean) {
    this.onCall.next(onCall);
  }

  getOnCall() {
    return this.onCall$;
  }

  setSubscriptionPlatform(isSubscribe: any) {
    this.subscriptionPlatform.next(isSubscribe);
  }

  getSubscriptionPlatform() {
    return this.subscriptionPlatform$;
  }

  setParameterSearch(parameter: any) {
    this.parameterSearch.next(parameter);
  }

  getParameterSearch() {
    return this.parameterSearch$;
  }


}
