import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {  MenuController } from '@ionic/angular';
import { filter, Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import {DataComponent,LayoutService} from 'src/app/shared/services/layout/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { SocketService } from '../../services/socket/socket.service';
import { DateService } from '../../services/utils/date.service';
import { ServiceOffertService } from '../../services/serviceOffert/service-offert.service';
import { ToastService } from '../../services/toast/toast.service';


@Component({
selector: 'app-menu-principal',
templateUrl: './menu-principal.component.html',
styleUrls: ['./menu-principal.component.scss'],
})
export class MenuPrincipalComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  user: any = {};
  allLust = [];
  subscription: Subscription;
  isUserIncognitoOrPrivate = false;
  // varible toogle circulo incognito
  toggleIncognitoCircle = false;
  // varible toogle circulo incognito
  componentSelect: DataComponent;

  //saber si es un usuario nuevo
  newUser = false;

  newPubSub: Subscription;
  showNewPub: boolean | any = false;
  showNotificationDonwloadMovie: boolean | any = false;;
  dataNotificationDownloadMovie: any;
  //variables para el summary
  balance1: string;
  balance2: string;
  balanceFrozen1: string;
  balanceFrozen2: string;
  //chats
  chats: any = [];
  userChatsSummary: any = [];
  idUserChatClient: any = [];
  //meets
  meets: any = [];
  durationMeets: any = [];
  userNameMeet: any = [];
  statusMeets: any = [];
  idUserMeetSummary: any = [];
  //notifcations
  notifications: any = [];
  dateNotification: any = [];
  //offerts
  offerts: any = [];
  userDataOfferts: any = [];
  idDataOfferts: any = [];
  //wishes
  wishes: any = [];
  userNameWish: any = [];
  idUserWish: any = [];
  //subscription model
  subscriptionModel: any = [];
  suscriberToClient: any = [];
  userNameModelSubs: any = [];
  followersModel: any = [];
  idSubscriptionModel: any = [];
  allSuscribers: any = [];
  //variables para el summary
  newWishSub: Subscription;
  newOfferSub: Subscription;
  newAttributesSub: Subscription;
  newServiceModalSub: Subscription;
  //tipo de usuario
  userIsModel: boolean;
  pathChangeMeetAdminbtn: string;
  btnContentMeetAdmin: string;
  srcBtnMeetAdmin: string;
  //tipo de usuario
  userSub: Subscription;
  notificationDownloadMovie: Subscription;
  dataWish: any = null;
  languageDefault = 'en';
  myId = '';
  activeUsers: any = [];
  allIUserActive: any = [];
  activeUserSubscription: Subscription;
  updateSummarySubscription: Subscription;
  authSubscription: Subscription;
    timeOutId: NodeJS.Timeout;
  timeOutActiveUser: NodeJS.Timeout;
  timeRefreshOnlineActiveUser: NodeJS.Timeout;
  userOnCall: boolean;
  userOnCallSubsciption: Subscription;
  subscriptionPlatform = false;
  subscriptionSubscriptionPlatform: Subscription;
  pageLoaded = false;
  billingActive: any;
  isModalBillingToPayment = false;
  closeModalBillingToPayment = false;

  userType = '';

  messageSubscription: any = {
    message: this.translate.instant('MODAL.PAY_ACTIVE')
  };

  private sub = this.router.events
    .pipe(
      filter((event) => event instanceof NavigationStart)
    )
    .subscribe((event: NavigationStart) => {
      let component: DataComponent = {
        component: 'recomendation',
        contextId: '',
      };

      if (this.componentSelect.component !== 'chat' && !event.url.includes('buy-product') && event.url !== '/wish-list') {
        this.layoutService.setComponent(component);
      }

      if (event.url === '/products-list') {
        component = { component: 'my-products', contextId: '' };
        this.layoutService.setComponent(component);
      }
    });


  get isIncognito() {
    return this.isUserIncognitoOrPrivate && this.getUserType() === '2';
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private authService: AuthServiceService,
    private socketService: SocketService,
    private menu: MenuController,
    private router: Router,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private usersService: UsersService,
    private dateService: DateService,
    private serviceOffertService: ServiceOffertService,
    private toastService: ToastService

  ) {

    this.authService.isDeviceIOS = /iPhone|iPad/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
    this.userType = this.authService.getLocalStorage('userType');
    const myId = this.authService.getLocalStorage('userId');
    this.usersService.getModelSubscriptions(myId).subscribe((subs: any) => {
      this.allSuscribers = subs;
    });

     this.getUser();
    let language = localStorage.getItem('language')?.toString();
    if (!language) {
      language = 'en';
    }
    this.selectlanguage(language);
    this.initiliceMenu();
    //this.changeActivePath(window.location.pathname);
    this.layoutService.getComponent().subscribe((component: DataComponent) => {
      this.componentSelect = component;
    });

    if (this.isAuthenticated) {
    this.getSummary();
    this.getOnlineUser();
    this.refreshOnlineUser();
    }

    this.updateSummarySubscription = this.layoutService.getUpdateStatus().subscribe((res) => {
      if (res) {
        this.getSummary();
      }
    }
    );

    this.authSubscription = this.authService.auth$.subscribe((isAuth) => {
      if (isAuth) {
        this.initiliceMenu();
      }
    });

    this.userOnCallSubsciption = this.layoutService.getOnCall().subscribe((userOnCall: boolean) => {
      this.userOnCall = userOnCall;
    });


    this.subscriptionSubscriptionPlatform = this.layoutService.getSubscriptionPlatform().subscribe((val) => {
      this.subscriptionPlatform = val;
    });

    this.notificationDownloadMovie = this.layoutService.getDataNotificationDownloadMovie().subscribe((val: any) => {
      if (val !== null) {
      this.showNotificationDonwloadMovie = val.open;
      this.dataNotificationDownloadMovie = val;
      }
    });
  }

  countActives() {

  }

   limitarTexto(texto: string): string {
    if (texto.length > 15) {
      return texto.substring(0, 15) + '...';
    }
    return texto;
  }

  // aqui actualiza los online y tambien el online propio nunca se va a desnectar mienstras este activa la plataforma
  getOnlineUser() {
        clearTimeout(this.timeOutActiveUser);
    this.usersService.getUsersActiveServer().subscribe(user => {
       this.activeUsers = user;
        this.allIUserActive = user;
        this.timeOutActiveUser = setTimeout(() => {
          this.getOnlineUser ();

        },300000 );
      });
  }

  refreshOnlineUser() {
      const myId = this.authService.getLocalStorage('userId');
      clearTimeout(this.timeRefreshOnlineActiveUser);
      this.usersService.refreshOnlineUser(myId).subscribe(user => {
      this.timeRefreshOnlineActiveUser = setTimeout(() => {
          this.refreshOnlineUser ();
        },150000 );
      });
  }

  initiliceMenu() {
    this.usersService.getSubscriptionActiveClient();
    this.changeDataUser();
   this.getOnlineUser();
    const isAuth =this.isAuth();

    if (isAuth) {

      this.usersService.getMyProfile().subscribe(
        (res: any) => {
          this.myId = res.dataUser._id;
          if (this.getUserType() === '2') {
            this.isUserIncognitoOrPrivate = res.dataUser.incognito;
            this.toggleIncognitoCircle = res.dataUser.incognito;
          } else {
            this.isUserIncognitoOrPrivate = res.dataUser.privateProfile;
          }

        },
        (error) => {
          console.error(error);
        }
      );
    }

  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 44) {
      event.preventDefault();
    }
  }

  transformActiveUserLength(activeUser: any[]) {
  let formattedLength: string;
  const userCount = activeUser.length;

  if (userCount >= 1000000) {
    formattedLength = (userCount / 1000000).toFixed(1) + 'm';
  } else if (userCount >= 1000) {
    formattedLength = (userCount / 1000).toFixed(1) + 'k';
  } else {
    formattedLength = userCount.toString();
  }

  return formattedLength;
}


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.sub) {
          this.sub.unsubscribe();
    }
    if (this.notificationDownloadMovie) {
      this.notificationDownloadMovie.unsubscribe();
    }

    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
    if (this.updateSummarySubscription) {
       this.updateSummarySubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    if (this.userOnCallSubsciption) {
      this.userOnCallSubsciption.unsubscribe();
   }

    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }

    if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
    }
  }

  ionViewWillLeave(): void {
      if (this.subscription) {
      this.subscription.unsubscribe();
    }
      if (this.sub) {
          this.sub.unsubscribe();
    }
      if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
    if (this.updateSummarySubscription) {
       this.updateSummarySubscription.unsubscribe();
    }
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
    if (this.timeOutActiveUser) {
      clearTimeout(this.timeOutActiveUser);
    }
  }

  getSummary() {
    clearTimeout(this.timeOutId);
       this.getUser();
    this.usersService.getSummary().subscribe(
      (res: any) => {
        if (res.offerts?.length === 0 || res.wishes?.length === 0 || res.meets?.length===0) {
          this.newUser = true;
        };
        if (res.offerts?.length > 0 || res.wishes?.length > 0 || res.meets?.length>0) {
          this.newUser = false;
        };

        this.subscriptionModel = [];
        this.meets = [];
        this.offerts = [];
        this.wishes = [];

       /* if (res.billingActive) {
          this.billingActive = res.billingActive;
          if (this.billingActive.total >= 50 && this.closeModalBillingToPayment == false) {
            this.isModalBillingToPayment = true;
          }

        }*/

        if (res.amount === 0) {
          this.balance1 = '0';
          this.balance2 = '00';
        } else {
        this.balanceTransform(res.amount);
        }

        if (res.frozenAmount
 === 0) {
          this.balanceFrozen1 = '0';
          this.balanceFrozen2 = '00';
        } else {
          this.balanceTransformFrozen(res.frozenAmount
);
        }


        if (res.meets) {
          this.getDateMeetsUsers(res.meets);
        }
        if (res.chats ) {
          this.checkIdandMessages(res.chats);
        }
        if (res.wishes) {
          this.getWishSummary(res.wishes);
        }
        if (res.notifications) {
        this.getNotifications(res.notifications);
        }
        if (res.offerts) {
          this.getOffert(res.offerts);
        }
        if (res.subscriptions?.length === 0) {
          this.newUser = true;
        }
        if (res.subscriptions?.length > 0) {
          this.newUser = false;
          this.getSubscriptionsModel(res.subscriptions);
        }
         if (res.subscriptionsToClient?.length === 0) {
          this.newUser = true;
        }
        if (res.subscriptionsToClient?.length > 0) {
          this.newUser = false;
          this.getSuscriberToClient(res.subscriptionsToClient);
        }

        this.timeOutId = setTimeout(() => {
          this.getSummary();

        },300000 );
      }
    );
  }


 showSubscribers() {
    this.layoutService.setShowModalSubsFollow(true);
    const payload = {
      data: this.allSuscribers,
      type: 'subscriptions'
    };
    this.layoutService.setDataModalSubsFollow(payload);
  }
  closeModalDebt() {
    this.closeModalBillingToPayment = true;
    this.isModalBillingToPayment = false;
  }

  openModalUserActive() {
    // const payload = {
    //   data: this.allIUserActive,
    //   type: 'on-Line'
    // };
    // this.layoutService.setShowModalOnline(true);
    // this.layoutService.setDataModalOnline(payload);
    //  this.closeMenu();
  }

  confirmModalDebt() {
    this.closeModalDebt();
     this.layoutService.setDataModalBillTicketDetail(this.billingActive);
    this.layoutService.setShowModalBillTicketDetail(true);

  }

  isActive(id: string) {
      return this.activeUsers.find(userActive => userActive.user === id)?.active;
  }

  lastConection(id: string) {
    const date = this.activeUsers.find(userActive => userActive.user === id)?.lastInteraction;
    return  this.dateService.getDatelastConection(date);
  }
  /** metodos para el summary */
 balanceTransform(coin: number) {
  let formattedAmount: string;
  const decimalPart = (coin % 1).toFixed(2).split('.')[1] || '00'; // Obtener parte decimal
  const integerPart = Math.floor(coin); // Parte entera

  // Agregar nomenclatura de miles y millones
  if (integerPart >= 1000000) {
    formattedAmount = (integerPart / 1000000).toFixed(1) + 'm';
  } else if (integerPart >= 1000) {
    formattedAmount = (integerPart / 1000).toFixed(1) + 'k';
  } else {
    formattedAmount = integerPart.toString();
  }

  // Asignar valores a balance1 y balance2
  this.balance1 = formattedAmount;
  this.balance2 = decimalPart;
}


balanceTransformFrozen(coin: number) {
  let formattedAmount: string;
  const decimalPart = (coin % 1).toFixed(2).split('.')[1] || '00'; // Obtener parte decimal
  const integerPart = Math.floor(coin); // Parte entera

  // Agregar nomenclatura de miles y millones
  if (integerPart >= 1000000) {
    formattedAmount = (integerPart / 1000000).toFixed(1) + 'm';
  } else if (integerPart >= 1000) {
    formattedAmount = (integerPart / 1000).toFixed(1) + 'k';
  } else {
    formattedAmount = integerPart.toString();
  }

  // Asignar valores a balanceFrozen1 y balanceFrozen2
  this.balanceFrozen1 = formattedAmount;
  this.balanceFrozen2 = decimalPart;
}




   checkIdandMessages(arry?: any) {
      arry.forEach((el) => {
        if (el.messages.length >0) {
          this.chats.push(el);
         el.usersId.forEach(element => {
           if (this.myId !== element._id) {
            this.userChatsSummary.push(element.userName);
             this.idUserChatClient.push(element._id);
           }
         });
        }
      });
   }

   openRight() {
    this.menu.open('menu2');
   }

  goToChat(event: Event, idUser, wishRef, offertRef) {
    event.stopPropagation();
    const component: DataComponent = { component: 'chat', contextId: idUser };
    if (wishRef) {
     component.wishRef = wishRef;
    }
    if (offertRef) {
      component.offertRef = offertRef;
     }
    this.layoutService.setChatRoom(component);
    this.router.navigate(['chat-room']);
  }

  getDateMeetsUsers(arry: any) {
    // filtrar los meets entre este momento y 8 horas
    const meetsToday = arry.filter(meet =>
      new Date(meet.end).getTime() > new Date().getTime() &&
      new Date(meet.start).getTime() < new Date(new Date().setHours(new Date().getHours() + 8)).getTime()
    );
    this.layoutService.setMeetingsToday(meetsToday);
    arry.forEach(element => {

      if (element.userClientId._id !== this.myId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !element.userClientId.incognito ? element.userClientId.userName : 'incognito';
          element.otherUser = element.userClientId;
      } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !element.userSellerId.incognito ? element.userSellerId.userName : 'incognito';
        element.otherUser = element.userSellerId;
      }

      this.meets.push(element);
      this.statusMeets.push(element.status.name);
      const star = new Date(element.start);
      const end = new Date(element.end);
      const scd = end.getTime() - star.getTime();
      const extraTime = 10;
      const min = (scd / 60000) - extraTime;
      this.durationMeets.push(min);
    });
  }
  getNotifications(arry: any) {
    arry.forEach(el => {
      this.notifications.push(el);
      const today = new Date();
      const dateC = new Date(el.createdAt);
      const dateConver =today.getTime() -dateC.getTime();
      const fecha = Math.round(dateConver / 6000000);
      this.dateNotification.push(fecha);
    });
  }

  getOffert(arry: any) {
    arry.forEach(element => {
      this.offerts.push(element);
      if (element.userClientId._id !== this.myId) {
        this.userDataOfferts.push(!element.userClientId.incognito ? element.userClientId.userName : 'incognito');
        this.idDataOfferts.push(element.userClientId._id );
      }
      if (element.userSellerId._id !== this.myId) {
        this.userDataOfferts.push(element.userSellerId.userName);
        this.idDataOfferts.push(element.userSellerId._id);
      }
    });
  }


  // mostrando ticket Modal

  billTicketDetail(data: any) {
    this.layoutService.setDataModalBillTicketDetail(data);
    this.layoutService.setShowModalBillTicketDetail(true);
  }
  getWishSummary(arry: any) {
    arry.forEach(element => {
      this.wishes.push(element);
       if (element.userClientId._id !== this.myId) {
         this.userNameWish.push(!element.userClientId.incognito ? element.userClientId.userName : 'incognito');
         this.idUserWish.push(element.userClientId._id);
       }
        if (element.userSellerId._id !== this.myId) {
           this.userNameWish.push(element.userSellerId.userName);
       this.idUserWish.push(element.userSellerId._id);
      }

    });
  }
  getSubscriptionsModel(data?: any) {

    data.forEach((element: any) => {

      const iSeller = this.myId === element.userSellerId._id;
      this.subscriptionModel.push(element);
      this.userNameModelSubs.push(iSeller ? element.userClientId.userName : element.userSellerId.userName);
      this.followersModel.push(iSeller ? element.userClientId.followers: element.userSellerId.followers);
      this.idSubscriptionModel.push(iSeller ? element.userClientId._id : element.userSellerId._id);
    });
  }
  getSuscriberToClient(data?: any) {
      this.suscriberToClient = data;

  }

  changeDataUser() {
    //if (this.getUserType() === '1') {
      //this.userIsModel = true;
      this.btnContentMeetAdmin = 'BUTTON.SUPPORT';
      this.srcBtnMeetAdmin = 'assets/icon/Icons-Lust - Admin Chat.svg';
    //}

   /* if (this.getUserType() === '2') {
      this.userIsModel = false;
      this.btnContentMeetAdmin = 'GENERAL.MEETING';
      this.srcBtnMeetAdmin = '/assets/icon/calendar2.svg';
    }*/
  }


 //payment plaforma modal subcriptions
  openModalSubPlataform() {
   if(!this.subscriptionPlatform){
    if (this.userType === '1') {
      const data = {
        type: 'model',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }

     if (this.userType === '2') {

      const data = {
        type: 'client',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }
    this.layoutService.setShowModalPaymentSubsPlataform(true);
  }
}



  /**
   * Cambia el lenguaje de la aplicación
   */
  selectlanguage(lang) {
    this.languageDefault = lang;
    const isAuth = localStorage.getItem('userId');
    if (isAuth) {
      this.usersService.updateLanguage(lang).subscribe();
    }
    this.translate.setDefaultLang(lang);

    localStorage.setItem('language', lang);
  }

  /**
   * Obtiene la información del usuario al iniciar sessión
   */
  getUser() {
    const userToParse = this.authService.getLocalStorage('user');
    this.userSub = this.authService.getUser().subscribe((user: any) => {
      if (user) {
        this.user = user;
      }else{

        const user = JSON.parse(userToParse);
        this.authService.setUser(user);
      }
    });

      if (this.user?.contentManagerId) {
      this.socketService.receiverNotifcations(this.user?.contentManagerId);
    } else {
      this.socketService.receiverNotifcations(this.user?._id);
    }

  }

  goToMyProfile() {
    this.router.navigate(['/profile/', this.myId]);
     this.closeMenu();
  }

  /**
   * Cerrar sesión
   */
  closeSession() {
    this.usersService.deactivateUserActiveById().subscribe(res => {
      this.closeMenu();
      this.authService.closeSession();
      this.authService.pageLoaded = false;
      setTimeout(() => {
        this.authService.pageLoaded = true;
      }, 3800);
    }, err => console.log(err));
  }

  /**
   * Cerrar menu lateral
   */
  closeMenu() {
    this.menu.close('menu');
  }

  /**
   * Te dirige a un sitio sitio
   *
   * @param path La dirección a dirigir
   */
  navigateTo(path: string, id?: string) {

    if (id) {
      this.router.navigate([`/${path}/${id}`]);
    } else {
      this.router.navigate([`/${path}`]);
    }
    //this.changeActivePath(path);
    this.closeMenu();
  }

  clickButtonAdmin() {
    this.openAdminChat();
    /*if (this.userIsModel) {
      this.openAdminChat();
    } else {
      this.router.navigate([`/calendar/${this.myId}`]);
    }
    this.changeActivePath('/calendar/');*/
    this.closeMenu();
  }

  /**
   * Cambia el estado a la página activa
   *
   * @param path Ruta a la que se va a dirigir el usuario
  //  */
  // changeActivePath(path: string) {
  //   const newPath = path === '/' ? '/home' : path;
  //   this.pages.forEach((page) => {
  //     page.isActive = false;
  //     if (page.url === newPath) {
  //       page.isActive = true;
  //     }
  //   });
  // }

  /**
   * Confirma si el usuario está autenticado y obtiene el usuario
   */
  isAuth() {
    this.authService.isAuthenticated();
    this.subscription = this.authService.auth$.subscribe((res) => {
      this.isAuthenticated = res;
      if (this.isAuthenticated) {
        this.getUser();
      }
    });
    return this.authService.getLocalStorage('user') ? true : false;
  }

  /**
   * Obtiene el tipo de usuario (creador o usuario)
   */
  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  openAdminChat() {
    const component: DataComponent = { component: 'chat', contextId: 'admin' };
    this.layoutService.setChatRoom(component);
    this.menu.close('menu');
    setTimeout(() => {
      this.router.navigate(['chat-room']);
    }, 500);
  }

  changePrivateProfile(value: boolean) {
    this.usersService.changePrivateProfile({ privateProfile: value }).subscribe(
      () => {
        this.isUserIncognitoOrPrivate = value;

      },
      (error) => console.error(error)
    );
  }

  changeIncognito(value: boolean) {

    this.usersService.changeIncognito({ incognito: value }).subscribe(
      () => {
        this.isUserIncognitoOrPrivate = value;
      },
      (error) => console.error(error)
    );
  }


  selectElement(element: any, type: string) {
    if (type === 'meet') {
      this.layoutService.setIdMeetingDetail(element._id);
      this.router.navigate(['/calendar/', this.myId]);
    }
    if (type === 'subscription') {

      this.usersService.getDataProfile(element).subscribe((val: any) => {

        if (val.user.type.type === 1) {

          this.router.navigate(['/profile/', element]);
        } else if (val.user.type.type === 2){
          this.toastService.presentToast('PROFILE.CLIENT_NO_PUBLIC_PROFILE', 3000);
        }
      });
    }
       if (type === 'suscriber') {
      this.router.navigate(['/profile/', element]);
    }
    if (type === 'offer') {

      // this.serviceOffertService.getOffertDetail(element._id).subscribe(
      //   (offert: any) => {
      //     this.layoutService.setDataNewOffer(offert);
      //     this.layoutService.setShowNewOffer(true);
      //   },
      //   (err: any) => console.log(err)
      // );
    }
    if (type === 'wish') {

      const component: DataComponent = {
        component: 'wish-detail',
        contextId: element._id,
      };
       this.layoutService.setDataWishDetailModal(component);
        this.layoutService.setShowModalWishDetail(true);
    }
    this.closeMenu();
  }

  goToMenu(myId) {
    this.closeMenu();
    this.router.navigate(['/calendar/', myId]);
  }
  /*goToMeet(event: Event, channel) {
    event.stopPropagation();
    this.router.navigate(['/meeting/', channel]);
  }*/
}
