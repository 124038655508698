import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout/layout.service';

declare let videojs: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() fluid: boolean;
  @Input() controls: boolean;
  @Input() autoplay: boolean;
  @Input() aspectRatio: string;
  @Input() sourceSrc: string;
  @Input() sourceType: string;
  @Input() poster: string;
  @Input() height: number;
  @Input() width: number;

  playVideo = false;
  pauseVideo = false;
  observer: IntersectionObserver;

  progress = 0;
  currentTime = 0;
  duration = 0;
  isDragging = false;
  wasPlaying = false;

  player: any;
  subscriptionSubscriptionPlatform: Subscription;
  subscriptionPlatform = false;

  constructor(
    private layoutService: LayoutService,
  ) { }

  ngOnInit() {
    if (!this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform = this.layoutService.getSubscriptionPlatform().subscribe((val) => {
        this.subscriptionPlatform = val;
      });
    }

    this.setupObserver();

    this.player = videojs(this.target.nativeElement, {
      fluid: this.fluid,
      controls: false,
      loop: true,
      autoplay: false,
      aspectRatio: this.aspectRatio,
      sources: [{ src: this.sourceSrc, type: this.sourceType }],
      poster: this.poster,
      height: this.height,
      width: this.width,
      volume: 1,
      nativeControlsForTouch: false,
      muted: false,
      bigPlayButton: true,
      controlBar: {
        volumePanel: { inline: false },
      },
    });

    this.player.on('loadedmetadata', () => {
      this.duration = this.player.duration();

    });

    this.player.on('timeupdate', () => {
      if (!this.isDragging && !isNaN(this.duration) && this.duration > 0) {
        this.currentTime = this.player.currentTime();
        this.progress = (this.currentTime / this.duration) * 100;

      }
    });

    window.addEventListener('mousemove', this.onDrag.bind(this));
    window.addEventListener('mouseup', this.stopDrag.bind(this));
    window.addEventListener('touchmove', this.onDrag.bind(this));
    window.addEventListener('touchend', this.stopDrag.bind(this));
  }

  setupObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.playVideo = false;
            this.player.autoplay(true);
            this.player.play();
          } else {
            this.playVideo = true;
            this.player.autoplay(false);
            this.player.pause();
          }
        });
      },
      { threshold: 0.5 }
    );
    if (this.target && this.target.nativeElement) {
      this.observer.observe(this.target.nativeElement);
    }
  }

  startDrag(event: MouseEvent | TouchEvent) {
    this.isDragging = true;
    this.wasPlaying = !this.player.paused();
    this.player.pause();
    this.onDrag(event);
  }

  onDrag(event: MouseEvent | TouchEvent) {
    if (!this.isDragging) {return;}

    const progressContainer = document.querySelector('.progress-container') as HTMLElement;
    const rect = progressContainer.getBoundingClientRect();
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const newTime = ((clientX - rect.left) / rect.width) * this.duration;

    if (!isNaN(newTime) && newTime >= 0 && newTime <= this.duration) {

      this.progress = ((clientX - rect.left) / rect.width) * 100;
      this.player.currentTime(newTime);
    }
  }

  stopDrag() {
    if (this.isDragging) {
      this.isDragging = false;
      if (this.wasPlaying) {
        this.player.play();
      }
    }
  }

  ngOnDestroy() {

    if (this.player) {
      this.player.dispose();
    }


    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
    }


    window.removeEventListener('mousemove', this.onDrag.bind(this));
    window.removeEventListener('mouseup', this.stopDrag.bind(this));
    window.removeEventListener('touchmove', this.onDrag.bind(this));
    window.removeEventListener('touchend', this.stopDrag.bind(this));
  }

  controlVideo() {
    if (this.player.paused()) {
      this.playVideo = false;
      this.player.volume(1);
      this.player.play();
    } else {
      this.playVideo = true;
      this.player.pause();
    }
  }
}
